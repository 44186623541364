@mixin generate($letter, $type) {
	$i: 0;
	@while $i <= 30 {
		.#{$letter} {
			&-#{$i} {
				#{$type}: (1px * $i) !important;
			}
			&x-#{$i} {
				#{$type}-left: (1px * $i) !important;
				#{$type}-right: (1px * $i) !important;
			}
			&y-#{$i} {
				#{$type}-top: (1px * $i) !important;
				#{$type}-bottom: (1px * $i) !important;
			}
			&t-#{$i} {
				#{$type}-top: (1px * $i) !important;
			}
			&b-#{$i} {
				#{$type}-bottom: (1px * $i) !important;
			}
			&l-#{$i} {
				#{$type}-left: (1px * $i) !important;
			}
			&r-#{$i} {
				#{$type}-right: (1px * $i) !important;
			}
		}
		$i: $i + 5;
	}
}
@include generate(p, padding);
@include generate(m, margin);
.mx-auto {
	margin-left: auto !important;
	margin-right: auto !important;
}
.w-100 {
	width: 100%;
}

.wh-100 {
	height: 100vh;
}

/*
    SPACING VARIABLES
*/
$spacing-1x: 4px;
$spacing-2x: 2 * $spacing-1x; // 8px
$spacing-3x: 3 * $spacing-1x; // 12px
$spacing-4x: 4 * $spacing-1x; // 16px
$spacing-5x: 5 * $spacing-1x; // 20px
$spacing-6x: 6 * $spacing-1x; // 24px
$spacing-7x: 7 * $spacing-1x; // 28px
$spacing-8x: 8 * $spacing-1x; // 32px
$spacing-9x: 9 * $spacing-1x; // 36px
$spacing-10x: 10 * $spacing-1x; // 40px
$spacing-11x: 11 * $spacing-1x; // 44px
$spacing-12x: 12 * $spacing-1x; // 48px
$spacing-13x: 13 * $spacing-1x; // 52px
$spacing-14x: 14 * $spacing-1x; // 56px

$n-spacing-1x: -1 * $spacing-1x; // -4px
$n-spacing-2x: 2 * $n-spacing-1x; // -8px
$n-spacing-3x: 3 * $n-spacing-1x; // -12px
$n-spacing-4x: 4 * $n-spacing-1x; // -16px
$n-spacing-5x: 5 * $n-spacing-1x; // -20px
$n-spacing-6x: 6 * $n-spacing-1x; // -24px
$n-spacing-7x: 7 * $n-spacing-1x; // -28px
$n-spacing-8x: 8 * $n-spacing-1x; // -32px
$n-spacing-9x: 9 * $n-spacing-1x; // -36px
$n-spacing-10x: 10 * $n-spacing-1x; // -40px
$n-spacing-11x: 11 * $n-spacing-1x; // -44px
$n-spacing-12x: 12 * $n-spacing-1x; // -48px
$n-spacing-13x: 13 * $n-spacing-1x; // -52px
$n-spacing-14x: 14 * $n-spacing-1x; // -56px
