// https://getbootstrap.com/docs/4.0/utilities/flex/
.d-flex {
	display: flex;
}
.d-inline-flex {
	display: inline-flex;
}

.flex-row {
	flex-direction: row;
}
.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-column {
	flex-direction: column;
}
.flex-column-reverse {
	flex-direction: column-reverse;
}

.justify-content-start {
	justify-content: flex-start;
}
.justify-content-end {
	justify-content: flex-end;
}
.justify-content-center {
	justify-content: center;
}
.justify-content-between {
	justify-content: space-between;
}
.justify-content-around {
	justify-content: space-around;
}

.align-items-start {
	align-items: flex-start;
}
.align-items-end {
	align-items: flex-end;
}
.align-items-center {
	align-items: center;
}
.align-items-baseline {
	align-items: baseline;
}
.align-items-stretch {
	align-items: stretch;
}

.align-self-start {
	align-self: flex-start;
}
.align-self-end {
	align-self: flex-end;
}
.align-self-center {
	align-self: center;
}
.align-self-baseline {
	align-self: baseline;
}
.align-self-stretch {
	align-self: stretch;
}

.flex-nowrap {
	flex-wrap: nowrap;
}
.flex-wrap {
	flex-wrap: wrap;
}
.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}

.order-0 {
	order: 0;
}
.order-1 {
	order: 1;
}
.order-2 {
	order: 2;
}
.order-3 {
	order: 3;
}
.order-4 {
	order: 4;
}
.order-5 {
	order: 5;
}
.order-6 {
	order: 6;
}
.order-7 {
	order: 7;
}
.order-8 {
	order: 8;
}
.order-9 {
	order: 9;
}
.order-10 {
	order: 10;
}
.order-11 {
	order: 11;
}
.order-12 {
	order: 12;
}

.align-content-start {
	align-content: flex-start;
}
.align-content-end {
	align-content: flex-end;
}
.align-content-center {
	align-content: center;
}
.align-content-around {
	align-content: space-around;
}
.align-content-stretch {
	align-content: stretch;
}

.flex-grow {
	flex-grow: 1;
}

.flex-center {
	display: flex;
	align-items: center;
}
