@mixin container-padding-mixin($top: 0, $bottom: $top) {
	padding: $top $container-padding $bottom;
}

@mixin label {
	font-size: $font-size-label;
	padding-bottom: 4px;
	color: $color_text_muted;
	// font-weight: bold;
}

@mixin error-message {
	background-color: $color-danger;
	border-radius: $border-radius;
	padding: $spacing-2x $spacing-3x;
	color: white;
}

@mixin header {
	display: flex;
	justify-content: space-between;
	align-items: center;

	> h1,
	> h2,
	> h3 {
		flex-grow: 1;
	}

	> button {
		margin-left: $spacing-3x;
	}

	@media screen and (max-width: $mobileBreakpoint) {
		align-items: flex-start;
		padding-bottom: 8px;
		flex-direction: column;

		h1,
		h2,
		h3 {
			min-width: fit-content;
		}

		> button {
			margin-left: 0;
			font-size: 14px;
			padding: 6px;
		}
	}
}

@mixin hide-in-print {
	@media print {
		display: none !important;
	}
}

@mixin ellipsis {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

@mixin whiteBackground {
	padding: $spacing-3x $spacing-4x $spacing-4x;
	border-radius: $border-radius;
	background-color: white;
}

@mixin grid {
	display: grid;
	gap: $spacing-6x;
	margin-bottom: $spacing-3x;
}

@mixin boxShadow {
	border-top: 1px solid rgba($color_header_background, 0.1);
	box-shadow: $boxShadow;
	transition: 0.2s opacity;
}

@mixin border-vertical {
	content: '';
	height: 0;
	width: 100%;
	position: absolute;
	pointer-events: none;
}

@mixin border-horizontal {
	content: '';
	height: 100%;
	width: 0;
	position: absolute;
	pointer-events: none;
}

@mixin border-bottom($inline: false) {
	@include border-vertical;

	@if $inline {
		top: 0;
	} @else {
		bottom: 0;
	}

	box-shadow: 0 -3px 7px 2px rgba(0, 0, 0, 0.4);
	border-bottom: $boxshadow-border;
	clip-path: polygon(50% 4px, 100% 0, 0 0);

	@content;
}

@mixin activeAndHover($isActive: false) {
	filter: brightness(90%);
	&.isActive,
	&.active {
		filter: brightness(120%);
	}
	&:hover {
		text-decoration: underline;
	}
}

@mixin groupContainer {
	display: flex;
	align-items: center;
	padding-top: 6px;
	padding-bottom: 4px;
	border-bottom: $border-bottom;
}

@mixin headerContainer {
	h1,
	h2 {
		margin: 0;
	}

	@include groupContainer();
}

@mixin elementContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 20px;

	.elementTitle {
		flex-basis: 30%;
		margin-right: 10px;
	}

	.elementContent {
		flex-basis: calc(70% - 10px);
		white-space: pre-line;
		word-break: break-word;
	}

	.elementHeader {
		flex-basis: 100%;
	}

	.elementListContentItem:not(:last-child) {
		margin-bottom: 8px;
	}
}

@mixin prettyTextFormat {
	white-space: pre-wrap;
	word-break: break-word;
}

@mixin iconContainer {
	display: flex;
	align-items: flex-end;

	& + & {
		margin-top: $spacing-2x;
	}

	.icon {
		margin-right: $spacing-2x;
	}
}
