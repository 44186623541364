@import 'styles/variables.scss';
@import 'styles/spacing.scss';
@import 'styles/flexbox.scss';
@import 'styles/colors.scss';

* {
	box-sizing: border-box;
}

html {
	font-family: 'Lato', 'Roboto', 'sans-serif';
	font-size: 14px;
}

body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	font-family: 'Lato';
	overflow-y: scroll;
	margin: 0;
	background-color: $color_background;

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		color: $color_headings;
		margin-bottom: 8px;
		font-weight: 300;
		font-family: 'Lato', 'Roboto', 'sans-serif';
	}

	h1 {
		margin: 12px 0;
		font-size: 36px;
		font-weight: 500;
		font-family: 'Roboto' !important;
	}

	h2 {
		font-size: 28px;
		font-weight: 400;
		font-family: 'Roboto' !important;
	}

	h3 {
		font-size: 24px;
		font-family: 'Roboto' !important;
	}
}

a,
button,
input,
textarea,
select {
	font-family: 'Lato' !important;
}

a {
	color: inherit;
	text-decoration: none;
}

textarea,
p {
	line-height: 1.6rem;
	@include prettyTextFormat();
}

textarea,
input {
	&:disabled {
		background: white !important;
		color: rgba(black, 0.5);
	}
}

body,
input,
button {
	font-size: $font-size;
}

* {
	word-break: break-word;
	word-wrap: break-word;
}

.widget {
	text-align: left;
	position: relative;
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.widget-body {
	flex-grow: 1;

	> div:nth-child(3) {
		height: calc(100% - 48px);

		> div {
			position: relative;
			height: 100%;
		}
	}
}

.widget-tab {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	background-color: $color_background;
	overflow-y: scroll;
	overflow-x: hidden;
	z-index: 10;
	padding-bottom: 20px;
}

div.shadow {
	height: 12px;
	margin-top: -12px;
	z-index: 0;
	box-shadow: rgba(0, 0, 0, 0.5) 0 4px 2px;
}

div.cardbox {
	border-radius: 2px 2px 0 0;
	height: 8px;
}

span.consent-label {
	padding-right: 5px;
}

.tooltip .tooltiptext {
	visibility: hidden;
	width: 120px;
	overflow: visible;
	white-space: normal;
	color: #ffffff;
	text-align: left;
	border-radius: 6px;
	padding: 5px 0;
	padding-left: 6px;
	background-color: #616161;

	font-family: Roboto;
	font-size: 13px;
	font-weight: normal;
	font-style: normal;
	font-stretch: normal;
	line-height: 1.54;
	letter-spacing: normal;
	text-align: left;

	/* Position the tooltip */
	position: absolute;
	z-index: 1;
	bottom: 60%;
	left: 25%;
	margin-left: -60px;
}

.tooltip .large {
	width: 320px;
	height: 60px;
}

.tooltip:hover .tooltiptext {
	visibility: visible;
}

.subheader {
	line-height: 48px;
	padding-left: 16px;
	font-size: 18px;
	font-weight: bold;

	.fa {
		width: 35px;
	}
}

.activity-details {
	border: none;
	table-layout: fixed;

	.name {
		font-weight: bold;
		width: 25%;
		min-width: 84px;
	}

	.value {
		width: 75%;
		font-weight: normal;
	}

	.wide {
		padding-left: 15px;
	}

	td {
		padding: 5px;
	}
}

.d-block {
	display: block;
}

.qvContainer {
	grid-column: 1 / span 2;
}

.smallText {
	font-size: 0.9em;
}

@media screen and (max-width: $mobileBreakpoint) {
	body {
		h1,
		h2,
		h3 {
			margin: 12px 0;
		}

		h1 {
			font-size: 24px;
		}

		h2 {
			font-size: 22px;
		}

		h3 {
			font-size: 22px;
		}
	}
}
