@import 'styles/spacing.scss';
@import 'styles/mixins.scss';

///////////// COLORS /////////////
$baseHue: 204;

$lowSaturation: 30%; // Bruges kun til baggrund
$baseSaturation: 57%; // Bruges kun til baggrund
$highSaturation: 71%; // Bruges kun til tekst og headers

$brightLuminance: 89;
$baseLuminance: 35%;
$darkLuminace: 12%; // Bruges kun til tekst og shadow

// baggund
$color_background: hsl($baseHue, $lowSaturation, 97%);
$color_background_light: lighten($color_background, 2%);
$color_background_dark: darken($color_background, 10%);

// header baggrund
$color_header_background: hsl($baseHue, $baseSaturation, $baseLuminance);
$color_header_background_light: lighten($color_header_background, 10%);
$color_header_background_dark: darken($color_header_background, 10%);

// spinner
$color_background_spinner: hsla($baseHue, $baseSaturation, 96%, 0.8);
$color_background_spinner: hsla($baseHue, $baseSaturation, 96%, 0.8);

// Tekst
$color_text: hsl($baseHue, $highSaturation, $darkLuminace);
$color_text_muted: lighten($color_text, 5%);

// Headers
$color_headings: lighten($color_text, 15%);

// Buttons
$color_button_background: hsl($baseHue, $baseSaturation, 91%);
$color_button_background: hsl($baseHue, $baseSaturation, 91%);
$color_call_to_action: #ff791a;
$color_clickable: #1b6da4;

// kui
$color_kui: #2d8484;
$color_kui_light: lighten($color_kui, 5%);
$color_kui_dark: darken($color_kui, 5%);

// andet
$link_active_hover: #ffa100;
$color_tooltip_background: hsl(0, 0%, 13%);

$color_primary: hsl(195, 70%, 46%);
$color-danger: hsl(0, 74%, 67%);

$system_colors: (
	'momentum': #4a90e2,
	'fasit': #4a90e2,
	'nexus': #4caf50,
	'uvvej': #9013fe,
	'unoung': #9013fe,
	'dubu': #f5a623,
);

// E.g: color: system_color('uvvej');
@function system_color($system) {
	@return map-get($system_colors, $system);
}

///////////// FONTS /////////////
$font-size: 16px;
$font-size-label: 16px;

///////////// FORMS /////////////
$input-height: 43px;
$input-height-with-label: 50px + $font-size-label;

///////////// BORDERS /////////////
$border-size: 13px;
$border-radius: 3px;
$border-color: $color_background_dark;
$border: 1px solid $border-color;
$border-bottom: 1px solid #e3e7e8;

///////////// PAGE /////////////
$max-content-width: 1050px;
$container-padding: Max(30px, calc((100% - #{$max-content-width}) / 2));
$navigation-height: 39px;

///////////// BOXSHADOWS /////////////
$boxShadow: 0 3px 7px -1px rgba($color_text, 0.2);
$boxShadowDropDown: 0 32px 40px 0 rgba(#4d4d4d, 0.3);
$boxshadow-border: 1px solid rgba($color_text, 0.05);

///////////// MOBILE /////////////
$mobileBreakpoint: 500px;

///////////// CRAPPY SCREENS /////////////
$crappyScreenHeightBreakpoint: 798px;
