@import 'styles/variables.scss';

@each $name, $color in $system_colors {
	.color-system-#{$name} {
		color: $color !important;
	}
	.bg-color-system-#{$name} {
		background-color: $color !important;
	}
}
